import React, { useRef, useState, useEffect } from "react";
import useForm from '../../customHooks/useForm';
import { Grid, Typography, Divider, Backdrop, CircularProgress } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { Paid } from '@mui/icons-material';
import SimpleReactValidator from 'simple-react-validator';
import ReauthenticateDialog from "../../components/Dialogs/ReauthenticateDialog";

import CardTemplate from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForceUpdate, toWithOutError, truncateFloat, formatAllNumber } from 'utils';
import ProgressButton from 'components/CustomButtons/ProgressButton';
import { fugaGreen } from "variables/colors";
import { CUPON, DEPOSITO } from '../../variables/financial';
import { checkPayoutOTPDocServer, createPayoutOTPDocServer, getUserAvailableMoney } from "services/BackendCommunication";
import { payoutCreateRequestRedux } from "redux/actions/PayoutsActions";
import InfoActionDialog from 'components/Dialogs/InfoActionDialog';
import { emailsNoEquals, payoutGenerated, payoutLessThanTen, taxesWarning } from '../../utils/textToShow.utils';
import SuccessDialog from 'components/Dialogs/SuccessDialog';
import { useNavigate } from 'react-router-dom';
import { userIsAdmin } from 'utils/users.utils';
import { checkPayoutFormValidations, getUserFinancialDataFromAccount, helperTextAvailable, transferTotalIsValid } from "utils/payouts.utils";
import { discountsCreateRedux } from "redux/actions/DiscountsActions";
import { discountModelForRoyaltyPayout } from "factory/discounts.factory";
import { getSystemUsdToArsRatio } from "services/FirestoreServices";
import { v4 as uuidv4 } from 'uuid';
import TwoAuthPayout from "./TwoAuthPayout";
import AccountDataForInternationalPayout from "./SubComponentsPayoutForm/AccountDataForInternationalPayout";
import AccountDataForCripto from "./SubComponentsPayoutForm/AccountDataForCripto";
import AccountDataForEmailBasedPayout from "./SubComponentsPayoutForm/AccountDataForEmailBasedPayout";
import AccountDataForLocalBankTransfer from "./SubComponentsPayoutForm/AccountDataForLocalBankTransfer";
import ApplicantDataForm from "./SubComponentsPayoutForm/ApplicantDataForm";
import CurrencyAndAccountType from "./SubComponentsPayoutForm/CurrencyAndAccountType";
import UserFinancialData from "./SubComponentsPayoutForm/UserFinancialData";

const PayoutForm = () => {

  const validator = useRef(new SimpleReactValidator());
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(store => store.userData);
  const isAdmin = userIsAdmin(userData.rol);
  const errorHandler = useSelector(store => store.errorHandler);

  const [openAlertDialog, setOpenAlertDialog] = useState({ open: false, title: "", text: [""] });
  const [openChangeCredentialsDialog, setOpenChangeCredentialsDialog] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [medioDePago, setMedioDePago] = useState({ name: "ARS", currency: "ars", account: 'bank' });

  const [confirmEmailBasedValue, setConfirmEmailBasedValue] = useState("");

  const [usdToArsRate, setUsdToArsRate] = useState("");
  const [planPriceInArs, setPlanPriceInArs] = useState(0);
  const [totalRoyaltiesAndPayed, setTotalRoyaltiesAndPayed] = useState({ royalties: 0, payed: 0, loading: true, available: 0, taxesUsd: 0 });
  const [cuponName, setCuponName] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(DEPOSITO);

  const [editState, setEditState] = useState("none");
  const [buttonText, setButtonText] = useState("Solicitar Regalías");

  const [userToAttachPayout, setUserToAttachPayout] = useState("");

  const defaultOtpInfoDialog = { open: false, payout: {} };
  const [otpDialogInfo, setOtpDialogInfo] = useState(defaultOtpInfoDialog);

  const bankOption = medioDePago.account === "bank";
  const paypalOption = medioDePago.account === "paypal";
  const cuponOption = medioDePago.account === "cupon";
  const wiseOption = medioDePago.account === "wise";
  const bankUsdOption = medioDePago.account === "bank_usd";
  const criptoUsdOption = medioDePago.account === "cripto"

  // Datos basicos del solicitnate y de montos
  let defaultData = {
    userName: userData.nombre || "", userLastName: userData.apellido || "",
    telefono: userData.telefono || "", ciudad: userData.ciudad || "",
    ownerEmail: userData.email, stats: userData.stats,
    ownerId: userData.id, transferTotalUsd: 0, id: uuidv4(),
  };

  const [formData, setForm] = useForm(defaultData);
  let { transferTotalUsd } = formData;

  // Datos para el formulario de pagos internacionales
  let usdTransferDefaultData = {
    fullName: "", email: "", country: "", city: "", bankAddress: "",
    postalCode: "", accountNumber: "", accountType: "Corriente", achRoutingNumber: "",
    wireRoutingNumber: "", swiftCode: "", transferType: "",
  };
  const [usdTransferFormData, setUsdTransferFormData, resetUsdTransferFormData] = useForm(usdTransferDefaultData);

  // Default data para cripto
  let criptoDefaultData = { cryptoType: "USDT", address: "", network: "" };
  const [criptoFormData, setCriptoFormData, resetCriptoFormData] = useForm(criptoDefaultData);

  // Default data para email based (wise, paypal)
  let emailBasedDefaultData = { email: "" };
  const [emailBasedFormData, setEmailBasedFormData, resetEmailBasedFormData] = useForm(emailBasedDefaultData);

  // Default data para local transfer
  let localTransferDefaultData = { cuit: "", cbuCvuAlias: "" };
  const [localTransferFormData, setLocalTransferFormData, resetLocalTransferFormData] = useForm(localTransferDefaultData);

  const handleResetAllValues = () => {
    resetLocalTransferFormData();
    resetEmailBasedFormData(); setConfirmEmailBasedValue("");
    resetCriptoFormData();
    resetUsdTransferFormData();
  }

  const [autoRenewPayout, setAutoRenewPayout] = useState(defaultData);

  // USD to ARS ratio
  useEffect(() => {
    const getUsdToArsRate = async () => {
      const usdToArs = await getSystemUsdToArsRatio(dispatch);
      setUsdToArsRate(usdToArs);
    }
    getUsdToArsRate();
  }, []);

  const handleCloseAlertDialog = () => setOpenAlertDialog({ open: false, title: "", text: [""] });

  const handleSubmit = async () => {
    let userFinancialData = getUserFinancialDataFromAccount(usdTransferFormData, localTransferFormData,
      emailBasedFormData, criptoFormData, medioDePago) || {};
    let payoutSettedData = isAdmin ? autoRenewPayout : formData;
    let resultEdit = await toWithOutError(dispatch(payoutCreateRequestRedux({
      ...payoutSettedData, currency: medioDePago.currency.toUpperCase(),
      currencyRateToUsd: medioDePago.currency === "ars" ? usdToArsRate : 1,
      transferTotalAskedCurrency: medioDePago.currency === "ars"
        ? truncateFloat(parseFloat(transferTotalUsd) * parseFloat(usdToArsRate), 2, '.') : 0,
      alreadyPaidUsd: parseFloat(totalRoyaltiesAndPayed.payed),
      historicTotalUsd: truncateFloat(parseFloat(totalRoyaltiesAndPayed.payed) + parseFloat(transferTotalUsd)),
      paymentMethod, commercialId: "", userFinancialData
    }, isAdmin)));

    if (paymentMethod === CUPON) {
      let discountFromPayout = discountModelForRoyaltyPayout(resultEdit.payout, isAdmin);
      resultEdit = await toWithOutError(dispatch(discountsCreateRedux(discountFromPayout)))
      if (resultEdit === "SUCCESS") setCuponName(discountFromPayout.name);
    }
    setEditState(resultEdit.result ? resultEdit.result : resultEdit);
  }

  const accountValuesAreNotEquals = confirmValue => {
    if (paypalOption || wiseOption) return confirmValue !== emailBasedFormData.email;
    return false;
  }

  const checkFields = () => {
    forceUpdate();
    if (!transferTotalIsValid(transferTotalUsd, totalRoyaltiesAndPayed.available, usdTransferFormData.transferType, usdToArsRate, planPriceInArs, medioDePago)) {
      setOpenAlertDialog({
        open: true,
        title: helperTextAvailable(transferTotalUsd, totalRoyaltiesAndPayed.available, usdTransferFormData.transferType, usdToArsRate, planPriceInArs, medioDePago),
        text: payoutLessThanTen(isAdmin ? userToAttachPayout.plan : userData.plan)
      }); return false;
    }
    if (isAdmin) return true;
    if (accountValuesAreNotEquals(confirmEmailBasedValue)) {
      setOpenAlertDialog({ open: true, title: "Los emails no coinciden.", text: emailsNoEquals }); return false;
    }
    if (checkPayoutFormValidations(medioDePago, usdTransferFormData, validator)) return true;
    else {
      validator.current.showMessages();
      forceUpdate();
      return false;
    }
  }

  const handleVerifiedOTP = async (payoutId, otp) => {
    let result = await toWithOutError(checkPayoutOTPDocServer(payoutId, otp, dispatch));
    return result;
  }

  const handleCreateOTP = async () => {
    setOpenLoader(true);
    console.log("HANDLE OTP: ", formData)
    if (checkFields()) {
      let result = await toWithOutError(createPayoutOTPDocServer(formData.id, userData.id, dispatch));
      if (result === "NEW_PAYOUT_OTP") setOtpDialogInfo({ open: true, payout: formData });
      if (result === "RESEND_OLD_PAYOUT_OTP") setOtpDialogInfo({ open: true, payout: formData });
      if (["ERROR", "ERROR_CREATING_OTP"].includes(result)) { }
    }
    setOpenLoader(false);
  };

  const handleCreatePayoutFromAdmin = () => {
    setOpenLoader(true);
    if (checkFields()) handleSubmit();
    setOpenLoader(false);
  }

  const handleCloseOtpDialog = () => setOtpDialogInfo(defaultOtpInfoDialog);

  useEffect(() => {
    if (editState === "SUCCESS") setButtonText("Regalías Solicitadas");
  }, [editState, errorHandler]);

  const onCheckMedioDePago = medioDePagoName => {
    handleResetAllValues();
    setMedioDePago({ ...medioDePago, account: medioDePagoName.id, accountName: medioDePagoName.label });
    setPaymentMethod(medioDePagoName.idDB);
    if (medioDePagoName.id === 'cupon' && !isAdmin) {
      const planInUsd = Math.ceil((planPriceInArs / usdToArsRate) * 100) / 100;
      setForm({ target: { name: 'transferTotalUsd', value: Math.min(planInUsd, totalRoyaltiesAndPayed.available) } });
    }
  }

  const handleGoToRetiros = () => navigate("/admin/retiros");

  return (
    <Grid container justifyContent="center">

      <Backdrop open={openLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <TwoAuthPayout isOpen={otpDialogInfo.open} handleOk={handleSubmit} handleClose={handleCloseOtpDialog}
        handleVerifiedOTP={handleVerifiedOTP} payout={formData} />

      <ReauthenticateDialog isOpen={openChangeCredentialsDialog} setIsOpen={setOpenChangeCredentialsDialog}
        textName={userData.nombre} />

      <SuccessDialog isOpen={editState === 'SUCCESS'} title={"¡Tu solicitud se ha procesado correctamente!"}
        contentTexts={payoutGenerated(medioDePago.account, cuponName)} handleClose={handleGoToRetiros}
        successImageSource="/images/success.jpg" size="sm" />

      <InfoActionDialog id='alert-payout' isOpen={openAlertDialog.open} handleClose={handleCloseAlertDialog}
        title={openAlertDialog.title} contentTexts={openAlertDialog.text} />

      <Grid item xs={12} md={11} lg={10}>
        <CardTemplate>

          <CardHeader color="primary" style={{ background: fugaGreen, textAlign: "center" }}>
            <Typography sx={cardTitleWhiteStyles}>Solicitar Regalías</Typography>
            <p style={cardCategoryWhiteStyles}>Todos los datos que proporciones deben ser correctos, por favor revisa el formulario antes de enviarlo.</p>
            <p style={cardCategoryWhiteStyles}>Las transferencias se realizan durante los próximos 10 días hábiles.</p>
            <p style={cardCategoryWhiteStyles}>Si solicitas un cupón de crédito, el mismo te llegará por email.</p>
            <p style={cardCategoryWhiteStyles}>Los reportes se cargan a partir del 15 de cada mes, en día hábil.</p>
          </CardHeader>

          <CardBody>
            <Grid container justifyContent="center">

              <UserFinancialData
                userData={userData}
                isAdmin={isAdmin}
                usdToArsRate={usdToArsRate}
                setForm={setForm}
                userToAttachPayout={userToAttachPayout}
                setUserToAttachPayout={setUserToAttachPayout}
                transferTotalUsd={transferTotalUsd}
                medioDePago={medioDePago}
                planPriceInArs={planPriceInArs}
                setPlanPriceInArs={setPlanPriceInArs}
                totalRoyaltiesAndPayed={totalRoyaltiesAndPayed}
                setTotalRoyaltiesAndPayed={setTotalRoyaltiesAndPayed}
                setAutoRenewPayout={setAutoRenewPayout}
                setMedioDePago={setMedioDePago}
                validator={validator}
                autoRenewPayout={autoRenewPayout}
                usdTransferFormData={usdTransferFormData}
              />

              <Grid item xs={12} textAlign="-moz-center" paddingTop={2} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              <Grid item xs={6} textAlign="center" paddingBottom={2}>
                <Typography sx={subtitlesStyles} >{`Moneda de retiro: ${medioDePago.currency === 'ars' ? "ARS" : "USD"}`}</Typography>
              </Grid>

              <Grid item xs={6} textAlign="center" paddingBottom={2}>
                <Typography sx={subtitlesStyles} >Medio de pago</Typography>
              </Grid>

              <CurrencyAndAccountType medioDePago={medioDePago} setMedioDePago={setMedioDePago} setPaymentMethod={setPaymentMethod}
                isAdmin={isAdmin} handleResetAllValues={handleResetAllValues} onCheckMedioDePago={onCheckMedioDePago} />

              <Grid item xs={12} textAlign="-moz-center" paddingTop={3} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              {!cuponOption &&
                <Grid item xs={12} textAlign="center" paddingBottom={2}>
                  <Typography sx={subtitlesStyles}>Datos de la Cuenta</Typography>
                </Grid>
              }

              {bankUsdOption && (
                <AccountDataForInternationalPayout formData={usdTransferFormData} setFormData={setUsdTransferFormData}
                  resetFormData={resetUsdTransferFormData} validator={validator} />
              )}

              {criptoUsdOption && (
                <AccountDataForCripto formData={criptoFormData} setFormData={setCriptoFormData} validator={validator} />
              )}

              {(wiseOption || paypalOption) && (
                <AccountDataForEmailBasedPayout formData={emailBasedFormData} setFormData={setEmailBasedFormData}
                  accountName={medioDePago.accountName} confirmEmail={confirmEmailBasedValue}
                  setConfirmEmail={setConfirmEmailBasedValue} validator={validator} />
              )}

              {bankOption &&
                <AccountDataForLocalBankTransfer formData={localTransferFormData}
                  setFormData={setLocalTransferFormData} validator={validator} />
              }

              {(bankOption || cuponOption) &&
                <Grid paddingTop={cuponOption ? 2 : 0}>
                  <Grid item xs={12} textAlign="center" paddingBottom={2}>
                    <Typography sx={moneyTextStyle} >{`Cotización USD/ARS: ${formatAllNumber(usdToArsRate, 2, '.')}`}</Typography>
                  </Grid>

                  <Grid item xs={12} textAlign="center" paddingBottom={2}>
                    <Typography sx={moneyTextStyle} >
                      {`Retiro en Pesos: ${formatAllNumber(parseFloat(transferTotalUsd) * parseFloat(usdToArsRate), 2, '.')}`}
                    </Typography>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12} textAlign="-moz-center" paddingTop={3} paddingBottom={2}>
                <Divider sx={{ borderWidth: '3px' }} />
              </Grid>

              {!isAdmin && <ApplicantDataForm formData={formData} setFormData={setForm} validator={validator} />}

            </Grid>
          </CardBody>

          {((isAdmin && userToAttachPayout.id) || !isAdmin) && <CardFooter>
            <Grid container item xs={12} justifyContent="center" spacing={2}>
              <ProgressButton
                textButton={buttonText}
                loading={openLoader}
                buttonState={editState.toLowerCase()}
                onClickHandler={isAdmin ? handleCreatePayoutFromAdmin : handleCreateOTP}
                disabled={false}
                noneIcon={<Paid />}
                color="secondary"
                backgroundColor={fugaGreen}
                noFab={false} />
            </Grid>
          </CardFooter>}

        </CardTemplate>
      </Grid>

    </Grid>
  );
}

export default PayoutForm;

const cardCategoryWhiteStyles = { color: "rgba(255,255,255,.82)", margin: "0 0 0", fontSize: "14px", fontWeight: "400" }
const subtitlesStyles = { fontSize: "30px", fontWeight: "bold", color: fugaGreen };
const moneyTextStyle = { fontSize: "18px", fontWeight: 400 };
const cardTitleWhiteStyles = {
  color: "rgba(255,255,255,255)",
  marginTop: "0px",
  minHeight: "auto",
  fontWeight: "300px",
  fontSize: "40px",
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  marginBottom: "3px",
  textDecoration: "none"
};