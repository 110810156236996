import React from "react";
import { Grid, Card, CardMedia, ButtonBase } from "@mui/material";
import CheckboxWithInfo from "components/Checkbox/CheckboxWithInfo";
import { fugaGreen } from "variables/colors";
import useWindowDimensions from "customHooks/useWindowDimensions";
import { DEPOSITO, mediosDePagoWithInfo, PAYPAL } from "variables/financial";

const CurrencyAndAccountType = ({ medioDePago, setMedioDePago, setPaymentMethod, handleResetAllValues, onCheckMedioDePago }) => {

  const windowDimensions = useWindowDimensions();
  let windowWidth = windowDimensions.width;
  let cardMediaWidth = windowWidth > 1427 ? 120 : 80;

  let borderHeight = 7;
  const cardStylePesos = {
    width: medioDePago.currency === "ars" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    height: medioDePago.currency === "ars" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    borderRadius: "1em", border: medioDePago.currency === "ars" ? 7 : 0, borderColor: fugaGreen,
  };

  const cardStyleUsd = {
    width: medioDePago.currency === "usd" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    height: medioDePago.currency === "usd" ? cardMediaWidth - borderHeight * 2 : cardMediaWidth,
    borderRadius: "1em", border: medioDePago.currency === "usd" ? 7 : 0, borderColor: fugaGreen,
  };

  const onClickCurrency = currency => {
    handleResetAllValues();
    setPaymentMethod(currency === "dolar" ? PAYPAL : DEPOSITO);
    setMedioDePago({
      name: currency === "dolar" ? "USD" : "ARS",
      currency: currency === "dolar" ? "usd" : "ars",
      account: currency === "dolar" ? "paypal" : "bank",
      accountName: currency === "dolar" ? "PayPal" : "bank",
    })
  }

  return (
    <Grid container spacing={2}>
      {/* Selección de Moneda */}
      <Grid item xs={3} textAlign="center">
        <ButtonBase>
          <Card sx={cardStylePesos} raised={true}>
            <CardMedia
              onClick={() => onClickCurrency("pesos")}
              sx={{ height: "100%" }}
              component="img"
              height="100%"
              image={"/images/pesos.jpg"}
              alt="pesos"
            />
          </Card>
        </ButtonBase>
      </Grid>

      <Grid item xs={3} textAlign="center">
        <ButtonBase>
          <Card sx={cardStyleUsd} raised={true}>
            <CardMedia
              onClick={() => onClickCurrency("dolar")}
              sx={{ height: "100%" }}
              component="img"
              height="100%"
              image={"/images/dolar.png"}
              alt="dolares"
            />
          </Card>
        </ButtonBase>
      </Grid>

      {/* Selección de Método de Pago */}
      <Grid container item xs={6} spacing={2} wrap="wrap">
        {mediosDePagoWithInfo
          .filter((mp) => mp.currency === medioDePago.currency)
          .map((medioDePagoConInfo) => (
            <Grid item xs={6} key={medioDePagoConInfo.id} textAlign="left">
              <CheckboxWithInfo
                checked={medioDePago.account === medioDePagoConInfo.id}
                onChecked={() => onCheckMedioDePago(medioDePagoConInfo)}
                label={medioDePagoConInfo.label}
                checkBoxHelper={medioDePagoConInfo.checkBoxHelper}
                labelGridSize={medioDePagoConInfo.checkBoxHelper ? (windowWidth > 1427 ? 3 : 8) : 12}
                labelTextAlign="start"
                color={fugaGreen}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default CurrencyAndAccountType;
